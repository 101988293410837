export const manageSharesFieldDetails = {
  STAKEHOLDERS: {
    label: 'Shareholder(s)',
    placeholder: 'Choose stakeholder(s)',
    description:
      "Select the person or group you'd like to give shares to, and turn those Stakeholders into Shareholders!",
  },
  PHASE: {
    label: 'Funding Round',
    placeholder: 'Select or create new Funding Round',
    description:
      'We have a list of common Funding Rounds to choose from. You can also create a new Funding Round and manage them in your Company Settings.',
  },
  VALUATION_AMOUNT: {
    label: 'Valuation',
    placeholder: 'Amount',
    secondaryPlaceholder: 'Type',
    description:
      'Here you can fill in against which Valuation the Shareholder invested. It’s important to also clarify pre- or post-money via the dropdown.',
  },
  SHARES_TYPE: {
    label: 'Type of Shares',
    description:
      'We have a list of common Type of Shares to choose from. You can also create a new Type of Shares and manage them in your Company Settings.',
  },
  SHARES_AMOUNT: {
    label: 'Number of Shares',
    tooltipTitle: 'Calculator',
    placeholder: 'Number',
    description:
      'We help you calculate the price per share for stakeholders based on the investment amount and total number of shares you provide.',
    extraDescription: 'The following formula is used:',
    boldDescription: 'Price per Share = Investment Amount / Number of Shares',
  },
  PRICE_PER_SHARE: {
    label: 'Price per Share',
    placeholder: 'Amount',
  },
  INVESTMENT_AMOUNT: {
    label: 'Investment Amount',
    placeholder: 'Amount',
  },
  TAG_ALONG: {
    label: 'Tag Along rights',
    description:
      'If a majority shareholder sells their stake, Tag Along rights give this shareholder the right to join the transaction and sell their minority stake in the company.',
  },
  DRAG_ALONG: {
    label: 'Drag Along rights',
    description:
      'If the majority stake in the company is sold at any point, Drag Along rights give this shareholder the right to force minority owners to sell their shares at the same price.',
  },
  VOTING: {
    label: 'Voting rights',
    description: 'This shareholder has rights to vote on corporate matters dictated in their shareholder agreement.',
  },
  PREEMPTIVE: {
    label: 'Preemptive rights',
    description:
      'This shareholder can buy future additional shares before shares are made available to the general public.',
  },
  LIQUIDATION: {
    label: 'Liquidation preference',
    description:
      'In the case that the company needs to be liquidated, this shareholder has priority in receiving their money ahead of those without liquidation preference.',
  },
};

export const FIRST_VALUATION_DISABLED_TOOLTIP = 'Valuation for the first transaction is disabled.';
