import { EnvironmentType } from '@app/shared/types/enums/environment-type.enum';
import packageConfig from '../../package.json'; // eslint-disable-line no-restricted-imports

export const environment = {
  environment: EnvironmentType.UAT,
  isProduction: true,
  baseUrl: 'https://uat.wevestrapp.com',
  prefix: '/api',
  sentryDSN: 'https://007e8a709b484531931247f030150ebe@o505067.ingest.sentry.io/5795202',
  segmentToken: 'dLSjgDeYXfNMNomQDCEi4OQd7vfz8ENk',
  userbackToken: <string>void 0,
  isMaintenanceEnabled: false,
  appVersion: packageConfig.version,
  isManageSharesDocumentsEnabled: true,
  stakeholderTemplateUrl: 'https://wevestr.s3.eu-central-1.amazonaws.com/templates/Stakeholder+List.xlsx',
  flagsmithEnvID: '62Ucfgq24qDnnyjr9aJbWS', // UAT ENV
  isFlagsmithEnabled: true,
  flagsmithBaseUrl: 'https://flagsmith.wevestrapp.com/api/v1/',
};
