<ng-container *wvLet="isValid$ | async as valid">
  <div
    class="form-field full-width"
    [ngClass]="{
      'valid-form-field': showValidFieldStyle && (addonInput ? valid && addonInput.valid : valid),
      'invalid-form-field':
        showInvalidFieldStyle && (addonInput ? addonInput.invalid || valid === false : valid === false)
    }"
    [ngClass]="formFieldClassNames"
  >
    <div *ngIf="label" class="labels-container full-width margin-bottom-s">
      <label [for]="formControlName" class="app-body-bold input-label">
        {{ label }}<span *ngIf="isRequired" class="required-input-sign">*</span>
      </label>
      <span *ngIf="additionalLabel" class="additional-input-label has-text-grey-light app-body-small">{{
        additionalLabel
      }}</span>
      <div
        *ngIf="tooltipText"
        class="info-icon"
        [matTooltip]="tooltipText"
        [matTooltipClass]="['info-tooltip', 'info-tooltip_top-alignment']"
        matTooltipPosition="right"
        ><span>i</span></div
      >
      <button
        *ngIf="labelButton"
        class="button-reset-styles label-button"
        [ngClass]="labelButton.classNames"
        [disabled]="labelButton.isDisabled"
        (click)="pressLabelButton.emit()"
        >{{ labelButton.text }}</button
      >
    </div>

    <div class="input-wrapper" *ngIf="type !== 'textarea'; else textarea">
      <input
        [attr.data-id]="dataId"
        #inputElement
        [(ngModel)]="value"
        [id]="formControlName"
        (blur)="onTouch()"
        [required]="isRequired"
        [type]="type"
        [ngClass]="inputClassNames"
        [placeholder]="placeholder"
        [disabled]="disabledState"
        [autocomplete]="autocomplete"
        [mask]="mask"
        [allowNegativeNumbers]="allowNegativeNumbers"
        [thousandSeparator]="thousandSeparator"
        [decimalMarker]="decimalMarker"
        (input)="onInput($event.target.value)"
      />
      <div class="clear-button-wrapper" *ngIf="hasClearButton && !isNil(value)">
        <button class="button-reset-styles clear-button" (click)="handleClearValue()">
          <mat-icon svgIcon="cross" class="clear-icon"></mat-icon>
        </button>
      </div>
      <ng-content select="[addon]"></ng-content>
    </div>
    <ng-template #textarea>
      <textarea
        #textAreaElement
        [(ngModel)]="value"
        [id]="formControlName"
        (blur)="onTouch()"
        [ngClass]="inputClassNames"
        [placeholder]="placeholder"
        [disabled]="disabledState"
      ></textarea>
    </ng-template>
    <ng-content select="[errors]"></ng-content>
  </div>
</ng-container>
